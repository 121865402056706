import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const PROCESS_COVERAGE_LEVEL_FOR_CONTRIBUTION_ACTION =
    'PROCESS_COVERAGE_LEVEL_FOR_CONTRIBUTION';

export const processCoverageLevelForContribution = (
    entityId: string,
    year: number,
    householdMemberNeedsCoverage: boolean,
    shouldClearSelectedPlansForReview: boolean,
    isPrimary: boolean
) =>
    commonAction(
        async () =>
            new UsersApi().processCoverageLevelForContribution(
                entityId,
                year,
                householdMemberNeedsCoverage,
                shouldClearSelectedPlansForReview,
                isPrimary
            ),
        PROCESS_COVERAGE_LEVEL_FOR_CONTRIBUTION_ACTION,
        {
            toastErrorMessage: 'Unable to update the household member',
            toastSuccessMessage: 'Successfully recalculated contribution amount',
        }
    );
